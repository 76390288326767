import React from 'react';
import styles from './Spinner.module.less';

/**
 * Spinner props types definitions
 */
type SpinnerPropsTypes = {
  /**
   * Show
   */
  show?: boolean,
  /**
   * Mini variant
   */
  mini?: boolean,
  /**
   * Show in button
   */
  inBtn?: boolean,
  /**
   * Additional className
   */
  className?: string,
  text?: string,
  mobileView?: boolean,
};

/**
 * Spinner component
 * @component
 * @category Components
 * @subcategory Utils
 * @borrows SpinnerPropsTypes as props
 * @hideconstructor
 * @example
 * return <Spinner mini show/>
 */
const Spinner = (props: SpinnerPropsTypes) => {
  const {
    show, mini, mobileView, inBtn, className, text,
  } = props;

  let resultClasses = styles.spinnerWrapper;
  if (show) resultClasses += ` ${styles.spinnerWrapperShow}`;
  if (mini) resultClasses += ` ${styles.spinnerWrapperMini}`;
  if (mobileView) resultClasses += ` ${styles.spinnerWrapperMobileView}`;
  if (inBtn) resultClasses += ` ${styles.spinnerWrapperBtn}`;
  if (className) resultClasses += ` ${className}`;

  return (
    <div className={resultClasses}>
      <div className={styles.spinner}>
        <div className={styles.spinnerCircle}>
          <svg
            id="spinner"
            strokeWidth="2"
            strokeMiterlimit="10"
            viewBox="25 25 50 50"
            className={`${styles.circular}${inBtn ? ` ${styles.circularBtn}` : ''}`}
          >
            <circle className={styles.path} cx="50" cy="50" r="20" fill="none"/>
          </svg>
        </div>
      </div>
      {text && <p className={styles.spinnerText}>{text}</p>}
    </div>
  );
};

export default Spinner;
