import { Method } from 'axios';
import userAuthorizedAxios from "./userAuthorizedAxios";

const customerAuthorizedFileDownloader = (
  url: string,
  data: any,
  fileName: string | null = null,
  method: Method = 'POST',
) => userAuthorizedAxios(url, {
  data,
  responseType: 'blob',
  method,
})
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    if (fileName === null) {
      link.setAttribute('download', response.headers['content-disposition'].split('"')[1] || fileName);
    } else {
      link.setAttribute('download', fileName);
    }
    document.body.appendChild(link);
    link.click();
  });

export default customerAuthorizedFileDownloader;
