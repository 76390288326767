import React, { ComponentClass, FunctionComponent, useEffect } from 'react';
import styles from './Popup.module.less';
import CloseBlock from '../CloseBlock/CloseBlock';
import { onKeyEnter } from '../../helpers/onKeyEnter';

/**
 * Popups props types definitions
 */
export type PopupPropsTypes = {
  /**
   * Close function
   */
  onClose: () => void,
  /**
   * Popup content
   */
  children: React.ReactComponentElement<FunctionComponent | ComponentClass> | React.ReactComponentElement<FunctionComponent | ComponentClass>[],
  /**
   * Is open
   */
  open: boolean,
  /**
   * Show or hide close icon
   */
  showCloseBlock?: boolean,
  /**
   * Additional popup classes
   */
  popupClasses?: string,
  /**
   * Additional close block classes
   */
  popupCloseBlockClasses?: string,
  /**
   * Additional overlay classes
   */
  overlayClasses?: string,
  /**
   * Set maxWidth
   */
  maxWidth?: number,
  /**
   * Set position top
   */
  top?: string,
  scrollable?: boolean,
};

/**
 * Popup component
 * @component
 * @category Components
 * @subcategory Content
 * @borrows PopupPropsTypes as props
 * @hideconstructor
 * @example
 *  * return <div style={{width: "250px", height: "190px"}}>
 *  *     <Popup onClose={() => {}}
 *  *     showCloseBlock
 *  *     open>
 *  *     <h2>Popup title</h2>
 *  *     <p>Popup text</p>
 *  *     </Popup>
 *  * </div>
 */
const Popup = (props: PopupPropsTypes) => {
  const {
    onClose,
    children,
    open,
    overlayClasses,
    popupClasses,
    popupCloseBlockClasses,
    showCloseBlock,
    maxWidth,
    top,
    scrollable,
  } = props;
  const escapePressListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') onClose();
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener('keydown', escapePressListener);
    return () => {
      document.removeEventListener('keydown', escapePressListener);
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      className={`${styles.popupOverlay} ${open ? styles.popupOpen : ''} ${overlayClasses || ''}`}
      onMouseDown={() => onClose()}
      onKeyDown={onKeyEnter(onClose)}
    >
      <div
        role="button"
        tabIndex={-1}
        className={`${styles.popup} ${popupClasses || ''}`}
        style={{
          maxWidth: maxWidth && `${maxWidth}px`,
          top,
          height: scrollable && `calc(100% - ${top || '21%'} - 24px)`,
          overflow: scrollable && 'auto',
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={onKeyEnter((e) => {
          e.stopPropagation();
        })}
      >
        <CloseBlock
          onClick={onClose}
          classes={`${styles.popupCloseBlock} ${showCloseBlock ? styles.popupCloseBlockShow : ''} ${popupCloseBlockClasses || ''}`}
        />
        {children}
      </div>
    </div>
  );
};

export default Popup;
