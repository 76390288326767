import moment from 'moment';
import userAuthorizedAxios from './userAuthorizedAxios';
import { BloodResult, BloodResultDto } from '../../interfaces/Blood/BloodResult';
import { MarkerRange } from '../../interfaces/Blood/MarkerRange';
import customerAuthorizedFileDownloader from './customerAuthorizedFileDownloader';
import { UserData } from '../../interfaces/Data/responceDataTypes';
import { CategoryResult, CategoriesForResult } from '../../interfaces/Blood/MarkerCategory';
import { PopulationPosition } from '../../interfaces/Blood/MarkerValue';

export default {
  shareByEmailAndFax: (name: string, email: string, fax: string): Promise<{ message: string }> => userAuthorizedAxios
    .post('/api/pages/bloodworkShare/share', {
      sharedName: name,
      sharedEmail: email,
      sharedFax: fax,
    })
    .then((response) => response.data),
  getResults: (): Promise<BloodResult[]> => userAuthorizedAxios
    .get('/api/customer/v1/blood/results')
    .then((response) => (response.data.items as BloodResultDto[]).map((result) => ({
      ...result,
      testedAt: moment(result.testedAt, 'YYYY-MM-DD'),
      publishedAt: moment(result.publishedAt, 'YYYY-MM-DD'),
    }))
      .sort((a, b) => (b.testedAt.isSameOrBefore(a.testedAt) ? -1 : 1))),
  getRanges: (): Promise<MarkerRange[]> => userAuthorizedAxios
    .get('/api/customer/blood/ranges')
    .then((response) => response.data.items),
  saveNotes: (resultId: number, title: string, comment: string) => userAuthorizedAxios.post(`/api/pages/tableViewNotes/submit/${resultId}`, {
    title,
    comment,
  }),
  deleteNotes: (resultId: number) => userAuthorizedAxios.delete(`/api/pages/tableViewNotes/delete/${resultId}`),
  getHistoryPdf: (user: UserData) => customerAuthorizedFileDownloader(
    '/api/customer/blood/report/results-history',
    null,
    `Bloodwork History for ${user.firstName} ${user.lastName}.pdf`,
    'GET',
  ),

  getUserMarkerCategories: (): Promise<CategoryResult[]> => userAuthorizedAxios.get('/api/customer/markers/categories')
    .then((responseData) => responseData.data.items),

  getCategoriesForResults: (): Promise<CategoriesForResult[]> => userAuthorizedAxios.get('/api/customer/markers/categories-for-each-result')
    .then((responseData) => responseData.data.items),

  getAvailableMarkerAbbrs: (): Promise<string[]> => userAuthorizedAxios.get('/api/customer/v1/blood/markers-for-view')
    .then((responseData) => responseData.data.abbrs),

  getPopulationPosition: (abbr: string): Promise<PopulationPosition> => userAuthorizedAxios.get(`/api/customer/blood/markers/${abbr}/population-position`)
    .then((responseData) => responseData.data),
};
