import React from 'react';

/* Styles */
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './AuthPanel.module.less';

/* Components */
import { userActivationStates } from '../../../../Constants/User';
import mainActionLinks from './parts/ActionLinks';
import { UserData } from '../../../../interfaces/Data/responceDataTypes';
import Icon from '../../../Icons/Icon';
import ITrackerLink from '../../../Link/Link';
import { configurationSelector } from '../../../../store/common/selectors';
import IconNames from "../../../../references/Icon/IconNames";

/**
 * Auth panel props types definitions
 */
type AuthPanelPropsTypes = {
  /** User data object */
  user: UserData | null,
  /** Router history object */
  history: any
};

/**
 * Auth panel - dropdown panel that includes logged in user actions
 * @category Header
 * @borrows AuthPanelPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * const user = {
 * id: 123,
 * email: 'test@test.com',
 * firstName: 'test',
 * middleName: 'test',
 * lastName: 'test',
 * phone: 'test',
 * phoneCode: 'test',
 * activationState: 'test',
 * hasInnerAgeResult: true,
 * isDnaOnly: true,
 * hasUnusedTest: true,
 * permissions: [],
 * photoSrc: '',
 * hasDiyPackage: true,
 * canAddInnerAge: true,
 * }
 * return <AuthPanel user={user} history={{}}/>
 */
const AuthPanel = (props: AuthPanelPropsTypes) => {
  const configuration = useSelector(configurationSelector);
  const { user, history } = props;
  return (
    <div className={styles.authorization}>
      <div className={styles.authorizationInner}>
        {mainActionLinks(history, user, configuration)}
        <div className={styles.authpanel}>
          <div className={styles.authData}>
            <div className={styles.authDataDescription}>
              {/* AVATAR */}
              <div className={styles.authDataPicture}>
                {user.photoSrc && (
                  <div
                    className={styles.authDataPictureAvatar}
                    style={{ backgroundImage: `url(${process.env.REACT_APP_API_HOST}/uploads/customer_photo/${user.photoSrc}` }}
                  />
                )}
                {!user.photoSrc && <Icon iconName={IconNames.general.avatar}/>}
              </div>
              <div className={styles.authDataOverlay}/>
            </div>
            <p className={styles.authDataLink}>{user.firstName}</p>
            <Icon className={styles.authDataIcon} iconName={IconNames.general.arrow}/>
          </div>
          {/* PROFILE DROPDOWN */}
          <ul className={styles.authMenu}>
            {user.activationState === userActivationStates.active && (
            <>
              {user.isDnaOnly && (
              <li className={styles.authMenuItem}>
                <a
                  id="upgrade"
                  className={styles.authMenuLink}
                  href="/customer/onboarding/choose-plan"
                >
                  Upgrade
                </a>
              </li>
              )}

              {!user.isDnaOnly && user.hasUnusedTest && (
              <li className={styles.authMenuItem}>
                <Link
                  id="use-test"
                  className={styles.authMenuLink}
                  to="/customer/onboarding/test-list/"
                >
                  Use test
                </Link>
              </li>
              )}

              {!user.isDnaOnly && !user.hasUnusedTest && user.canAddInnerAge && (
              <li className={styles.authMenuItem}>
                <Link
                  id="upgrade-test"
                  className={styles.authMenuLink}
                  to="/customer/onboarding/test-list/"
                >
                  Upgrade test
                </Link>
              </li>
              )}
              {!user.isDnaOnly && !user.hasUnusedTest && !user.canAddInnerAge && (
              <li className={styles.authMenuItem}>
                <a
                  id="order-test"
                  className={styles.authMenuLink}
                  href="/customer/onboarding/choose-plan"
                >
                  Order Test
                </a>
              </li>
              )}

              {user.hasAccessToBru && (
              <li className={styles.authMenuItem}>
                <Link
                  id="add-test-results"
                  className={styles.authMenuLink}
                  to="/customer/diy"
                >
                  Add test results
                </Link>
              </li>
              )}
              <li className={`${styles.authMenuItem} ${styles.authMenuItemDivider}`}/>
              <li className={styles.authMenuItem}>
                <ITrackerLink
                  id="health-profile"
                  className={styles.authMenuLink}
                  href="/customer/settings"
                >
                  Health profile
                </ITrackerLink>
              </li>
              <li className={styles.authMenuItem}>
                <ITrackerLink
                  id="orders"
                  className={styles.authMenuLink}
                  href="/customer/onboarding/test-list/"
                >
                  Orders
                </ITrackerLink>
              </li>
              <li className={styles.authMenuItem}>
                <ITrackerLink
                  id="purchase-history"
                  className={styles.authMenuLink}
                  href="/customer/purchase-history"
                >
                  Purchase History
                </ITrackerLink>
              </li>
              <li className={styles.authMenuItem}>
                <ITrackerLink
                  id="account"
                  className={styles.authMenuLink}
                  href="/customer/account"
                >
                  Account
                </ITrackerLink>
              </li>
            </>
            )}
            <li className={styles.authMenuItem}>
              <a
                id="contact-us"
                className={styles.authMenuLink}
                href="//insidetracker-faq.gorgias.help/"
              >
                Support center
              </a>
            </li>
            <li className={styles.authMenuItem}>
              <a
                id="contact-us"
                className={styles.authMenuLink}
                href="https://insidetracker-faq.gorgias.help/en-US/how-can-i-contact-you-288947"
              >
                Contact us
              </a>
            </li>
            <li className={styles.authMenuItem}>
              <Link
                id="log-out"
                className={styles.authMenuLink}
                to="/customer/login/logout"
              >
                Log out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuthPanel;
