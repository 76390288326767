import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reference from './reference';
import app from './app';
import user from './user';
import coach from './coach';
import admin from './admin';
import common from './common';
import userOrders from './customer/orders';
import { loadingsSetMiddleware } from '../helpers/loadingsSetMiddlware';
import userDna from './userDna';
import onboarding from './customer/onboarding';
import recommendations from "./bloodwork/recommendations";

const reducers = {
  app,
  user,
  userDna,
  admin,
  reference,
  common,
  coach,
  userOrders,
  onboarding,
  recommendations,
};

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk, loadingsSetMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

export default { store };
